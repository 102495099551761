// NpawPlugin and NpawPluginAdapters come from third party scripts
/* global NpawPlugin NpawPluginAdapters LogLevel */

import videojs from "video.js";
import { ENVIRONMENT } from "@utilities/Env";
import xPath from "@utilities/XPath";

/*
// Random note: I wasn't sure were else to put this but
// npaw tries to use a `getQuality` method a lot
// which is not implented in videojs-hlsjs-plugin
// I've made a small update to implement the method
// but not the fuctionality. If this functionality is
// required for an important metrics the relevent code block can
// be found here: https://github.com/hudl/bf-videojs-hlsjs-plugin/pull/2
*/

/*
// Options expected from config during player initialization:
// - 'accountCode' ('hudl' or 'hudldev')
// - 'username' (Hudl user id)
// - 'platform' or 'content.customDimension.1' (Platform custom dimension. 'V2' or 'V3' or 'FanExperience')
// - 'entityType' or 'content.customDimension.2' (Entity custom dimension. 'Video' or 'Playlist')
// - 'sport' or 'content.customDimension.3' (Sport custom dimension.)
// - 'angleType' or 'content.customDimension.4' (Angle custom dimension.)
// - 'teamId' or 'content.customDimension.5' (Hudl user's team id custom dimension.)
// - 'broadcastEncodingMethod or 'content.customDimension.6' (Whether a broadcast is encoded via BFMS or ODR)
*/

const Plugin = videojs.getPlugin("plugin");

// const ADAPTER_URL = "https://qa-smartplugin.youbora.com/catalog/v7/js/lib/last-build/samples/adapters-json/video/videojs/7.0.9-videojs-js.json";

const defaultYouboraOptions = {
	// This defaults to true, but we still require a youbora options object (with an accountCode set)
	// in the configuration object passed to the player constructor to actually enable youbora.
	"enabled": true,

	// Parse CDN information from .ts files.
	// http://developer.nicepeopleatwork.com/plugins/general/parse-hls/
	"parse.manifest": true,

	// https://hudl-jira.atlassian.net/browse/CV-1583
	// `parse.cdnTTL` default value of 60 seconds, this is the frequency of how often we want to check for cdn switches
	"parse.cdnSwitchHeader": true,

	// Enable CDN node and host detection.
	// http://developer.nicepeopleatwork.com/plugins/general/cdn-node-detection/
	"parse.cdnNode": true,
	"parse.cdnNode.list": [
		"Cloudfront",
		"Fastly",
		"Level3",
		"Media CDN",
		"Stackpath",
		"Quantil",
	],
};

function sanitizeCustomYouboraDimensions(options)
{
	if (options.platform)
	{
		options["content.customDimension.1"] = options.platform;
		delete options.platform;
	}
	if (options.entityType)
	{
		options["content.customDimension.2"] = options.entityType;
		delete options.entityType;
	}
	if (options.sport)
	{
		options["content.customDimension.3"] = options.sport;
		delete options.sport;
	}
	if (options.angleType)
	{
		options["content.customDimension.4"] = options.angleType;
		delete options.angleType;
	}
	if (options.teamId)
	{
		options["content.customDimension.5"] = options.teamId;
		delete options.teamId;
	}
	if (options.broadcastEncodingMethod)
	{
		options["content.customDimension.6"] = options.broadcastEncodingMethod;
		delete options.broadcastEncodingMethod;
	}
	if (options.broadcastID)
	{
		options["content.id"] = options.broadcastID;
		delete options.broadcastID;
	}
	if (options.broadcastTitle)
	{
		options["content.title"] = options.broadcastTitle;
		delete options.broadcastTitle;
	}
	if (options.appName)
	{
		options["app.name"] = options.appName;
		delete options.appName;
	}


	return options;
}

function getAccountCode(env)
{
	switch (env)
	{
	case ENVIRONMENT.PROD:
		return "hudl";
	case ENVIRONMENT.THOR:
	default:
		return "hudldev";
	}
}

function getEncodingMethod(src)
{
	if (src.includes("vcloud"))
	{
		// Example BFMS produced m3u8: https://vcloud.hudl.com/file/hls/<broadcast_id>.m3u8
		return "BFMS";
	}
	else if (src.includes("hudl.com"))
	{
		// Example ODR produced m3u8: https://ve.hudl.com/long/path/to/file/video.live.m3u8
		return "ODR";
	}
	else
	{
		return "Unknown";
	}
}

class YouboraPlugin extends Plugin
{
	/**
	 * Creates a YouboraPlugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [youboraConfig]
	 *      		Youbora configuration object. Roughly mirrors
	 * 					the object here: https://github.com/hudl/js-hudl-video-playback/blob/master/src/players/theo/youbora.config.js
	 */
	constructor(player, options)
	{
		super(player);
		if (!options.enabled)
		{
			return;
		}
		this.youboraConfig = options;
		this.youboraConfig.accountCode = getAccountCode(this.player.ENVIRONMENT);

		this.player.on("vmap-ready", () => { this.handleVmap(player.vmap); });
		this.player.on("hudlmetadata", (data) => { this.handleHudlMetadata(data); });

		const mergedOptions = videojs.mergeOptions(
			defaultYouboraOptions,
			this.youboraConfig
		);
		this.options = sanitizeCustomYouboraDimensions(mergedOptions);

		const accountCode = this.options.accountCode;
		delete this.options.accountCode;

		this._youboraPlugin = new NpawPlugin(accountCode);
		this._youboraPlugin.setLogLevel(LogLevel.ERROR);
		this._youboraPlugin.setAnalyticsOptions(this.options);
		this._youboraPlugin.registerAdapterFromClass(this.player, NpawPluginAdapters.video.Videojs);

		this._youboraPlugin.diagnostic.setBalancerEnabled(false);
		// todo turn off when ads are disabled
		this._youboraPlugin.diagnostic.setAdsAnalyticsEnabled(this.player.ENVIRONMENT === ENVIRONMENT.THOR);
		this._youboraPlugin.diagnostic.setVideoAnalyticsEnabled(this.player.ENVIRONMENT === ENVIRONMENT.THOR);

		if (!videojs.browser.IS_IOS)
		{
			this.player.on("adsmanagerready", (event, adsManager) =>
			{
				this._youboraPlugin.removeAdsAdapter();
				this._youboraPlugin.registerAdsAdapterFromClass(adsManager, NpawPluginAdapters.ads.Ima);
			});
			this.player.on("adsmanagerdestroyed", () =>
			{
				this._youboraPlugin.removeAdsAdapter();
			});
		}

		this.hasInitialized = true;
	}

	handleVmap(vmap)
	{
		const contentTag = xPath(vmap, "//Extensions/Extension[@type='VolarVMAPExtension']/Content").iterateNext();
		if (!contentTag || contentTag.textContent === "")
		{
			return;
		}

		const src = contentTag.textContent;

		const encodingMethod = getEncodingMethod(src);
		const broadcastTitle = xPath(vmap, "//Broadcast").iterateNext().getAttribute("title");

		this.youboraConfig.broadcastEncodingMethod = encodingMethod;
		this.youboraConfig.broadcastTitle = broadcastTitle;

		if (this.hasInitialized)
		{
			this.updateAnalyticsOptions();
		}
	}

	handleHudlMetadata(data)
	{
		this.youboraConfig.sport = data.sport;
		this.youboraConfig.teamId = data.organizationId;

		if (this.hasInitialized)
		{
			this.updateAnalyticsOptions();
		}
	}

	updateAnalyticsOptions()
	{
		const mergedOptions = videojs.mergeOptions(
			defaultYouboraOptions,
			this.youboraConfig
		);
		this.options = sanitizeCustomYouboraDimensions(mergedOptions);
		this._youboraPlugin.setAnalyticsOptions(this.options);
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("youbora", YouboraPlugin);
