import videojs from "video.js";
import xPath from "@utilities/XPath";
import { ENVIRONMENT } from "@utilities/Env";
const { XMLHttpRequest } = window;
import { isObject } from "@utilities/TypeChecks";
const logger = videojs.createLogger("HudlMetadataHandler");

const HTTP_STATUS = {
	OK: 200,
};

const XHR_STATUS = {
	READY: 4
};

const DOMAIN = {
	PROD: "www.hudl.com",
	THOR: "master.thorhudl.com"
};

function HudlMetadataHandler(options)
{
	if (!options.enabled) return;

	const broadcastID = options.broadcastID;
	const player = this;
	let metadataRequest = null;

	player.on("vmap-ready", () => { handleVmap(player.vmap); });
	player.on("statechange", handleStateChange);

	function handleStateChange(evt, state)
	{
		if (state === "UPCOMING" ||
			state === "TEST" ||
			state === "UNSET" ||
			state === "ERROR" ||
			state === "FINISHED"
		)
		{
			return;
		}

		fetchMetadata();

		player.off("statechange", handleStateChange);
	}

	function handleVmap(vmap)
	{
		let broadcastStatus = xPath(vmap, "//Broadcast").iterateNext().getAttribute("status");
		broadcastStatus = broadcastStatus.toLowerCase();
		if (broadcastStatus === "upcoming" ||
			broadcastStatus === "test" ||
			broadcastStatus === "testing")
		{
			player.on("statechange", handleStateChange);
			return;
		}

		fetchMetadata();
	}

	function fetchMetadata()
	{
		if (metadataRequest)
		{
			metadataRequest.abort();
		}
		metadataRequest = new XMLHttpRequest();
		metadataRequest.onreadystatechange = () =>
		{
			if (metadataRequest.readyState === XHR_STATUS.READY)
			{
				if (metadataRequest.status === HTTP_STATUS.OK)
				{
					const responseData = JSON.parse(metadataRequest.responseText);
					if (isObject(responseData))
					{
						player.hudlMetadata = responseData;
						player.trigger("hudlmetadata", responseData);
					}
					metadataRequest = null;
				}
				else if (metadataRequest.status === 0)
				{
					logger("Metadata request aborted");
				}
			}
		};
		const url = buildUrl(broadcastID, player.ENVIRONMENT);
		if (url === null) return;

		metadataRequest.open("GET", url, true);
		metadataRequest.send();
	}

	function buildUrl(id, env)
	{
		const domain = getDomain(env);
		if (!env)
		{
			return null;
		}

		return `https://${domain}/api/broadcasts/broadcast/${id}/metadata`;
	}

	function getDomain(env)
	{
		switch (env)
		{
		case ENVIRONMENT.PROD:
			return DOMAIN.PROD;
		case ENVIRONMENT.THOR:
			return DOMAIN.THOR;
		default:
			return null;
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("hudlMetadataHandler", HudlMetadataHandler);
