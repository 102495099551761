export const ENVIRONMENT = {
	PROD: "prod",
	THOR: "thor",
};

export function getEnvironment(vmapURL)
{
	try
	{
		const url = new URL(vmapURL);
		const hostname = url.hostname;

		const parts = hostname.split(".");

		// Assume the last two parts are the domain
		// It's expected that all prod vmaps will be hudl.com
		if (parts.length < 2)
		{
			return null;
		}

		// We don't have a way to determine our environment so, we'll make an assumption using the VMAP URL
		// eslint-disable-next-line no-magic-numbers
		const registeredDomain = parts.slice(-2).join(".");

		if (registeredDomain === "hudl.com")
		{
			return ENVIRONMENT.PROD;
		}
		else
		{
			return ENVIRONMENT.THOR;
		}
	}
	catch (error)
	{
		return null;
	}
}
