import videojs from "video.js";
import xPath from "@utilities/XPath";
import { getMimetype } from "@utilities/MimeTypes";

// TODO: (possible major overhaul, see below)
// I really like how I decided to implement VMAP handling as a SourceHandler
// instead of a generic plugin that swaps out the `src` on the player. This not
// only makes sense intuitively (the VMAP is our entire content and consists of
// multiple parts), but it makes interaction with the plugin much easier in
// many regards:
//    - You play a preroll before "the content", which is the WHOLE VMAP, not
//      the individual components of it (a pre-roll doesn't play between network
//      ID and main video)
//    - You play a postroll after "the content", which is the WHOLE VMAP, not
//      the individual components of it (a post-roll doesn't play between
//      network ID and main video)
//    - This allows us to pass the VMAP URL around the same way you would pass
//      around any other video URL. You can create a <video> tag with a `src`
//      attribute pointing to a VMAP and it works like magic
//
// With that said, there are two big issues I've run in to which may necessitate
// rethinking how we handle the VMAP
//    1. Reloading the content (`player.src(player.src())`) causes us to parse
//       the VMAP a second time (desirable, as we can learn about new VAST tags
//       or a change in the thumbnail URL or something), but also causes us to
//       restart our playback (undesirable as this creates a nasty skip in
//       playback). To make matters worse, when returning from AdBreaks this
//       reloading of the VMAP often meant we played the network ID again
//       rather than going straight back to content. To work around this, I said
//       to not reload the VMAP if the source URL hasn't changed. However this
//       takes away our ability to detect new VAST tags and the like (all of the
//       positive reasons for reloading the VMAP). This is just an architecture
//       problem and if I put some more thought into it then it should be
//       possible to improve our logic around this and make VMAP reloading easy
//       and powerful
//    2. Some plugins will attempt to play the current source. Notably,
//       Chromecast takes the current source and passes it to the Chromecast
//       device for playback. Because the Chromecast doesn't know how to play a
//       VolarVMAP, it fails. One way to work around this would be to create a
//       BlueFrame Chromecast app, and then pass in the app ID so that our
//       content loads in our app (which knows how to parse the VMAP). An
//       alternate solution involves getting the player to properly update the
//       `currentSource()` (separate from `src()`) so that we can pass an M3U8
//       URL to the Chromecast directly. In practice trying to do this ran into
//       many, many, many bugs. However even if I can work out these bugs, this
//       still isn't the ideal solution because we only pass a single M3U8 to
//       the Chromecast - not our full playlist. If you began casting during the
//       network ID, you would fail to make the leap from network ID to content.
//       The only way to fix this would be for our plugin to exist outside of
//       the Tech / SourceHandler realm and control the player from the outside.
//       However this takes away our ability to pass a VMAP URL directly to the
//       video's `src` attribute. Whatever solution we come up with, this is a
//       change that will have repercussions on the final behavior

const logger = videojs.createLogger("VolarVMAPSourceHandler");
const Component = videojs.getComponent("Component");

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState#Value
/*
const SOURCE_STATE = {
	HAVE_NOTHING: 0,
	HAVE_METADATA: 1,
	HAVE_CURRENT_DATA: 2,
	HAVE_FUTURE_DATA: 3,
	HAVE_ENOUGH_DATA: 4
};
*/

const PLAYER_STATE = {
	LOADING: 1,
	PLAYING: 2
};

const HTTP_STATUS = {
	OK: 200,
	FORBIDDEN: 403
};

const XHR_STATUS = {
	READY: 4
};

const _seekingThreshold = 0.1;
const _m3u8PollInterval = 3000;

/* eslint-disable no-magic-numbers */

// This video file was pulled from https://www.npmjs.com/package/can-autoplay
// It's theoretically the "smallest valid MP4 file"
// After running through UglifyJS the raw encoding (as stored in raw-video.js)
// came out to around 3500 bytes. By base64-encoding the data and replacing runs
// of A's with the printAs() method, this was reduced to nearly 1000 bytes -- a
// 2.5kb savings on our final bundle!

function printAs(count)
{
	// String.prototype.repeat is not supported in IE 11
	return new Array(count + 1).join("A");
}
const a10 = printAs(10);
const a15 = printAs(15);
const b64VIDEO = "AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAC721kYXQhEAUgpBv/w" + printAs(358) + "3pw" +
	printAs(122) + "cCEQBSCkG//" + printAs(358) + "Deng" + printAs(123) + "cAAAAsJtb292AAAAbG12aGQ" +
	printAs(19) + "PoAAAALwABAAAB" + a15 + "AAQ" + a10 + a10 + "E" + a15 + "AAAE" + printAs(41) +
	"DAAAB7HRyYWsAAABcdGtoZAAAAAM" + a15 + "IAAAAAAAAALw" + a15 + "QEAAAAAAQ" + a10 + a10 + "E" + a15 +
	"AAAE" + printAs(19) + "CRlZHRzAAAAHGVsc3Q" + a10 + "QAAAC8AAAAAAAEAAAAAAWRtZGlhAAAAIG1kaGQ" + a15 +
	"AAAKxEAAAIAFXEAAAAAAAtaGRscg" + a10 + "c291bg" + a15 + "FNvdW5kSGFuZGxlcgAAAAEPbWluZgAAABBzbWhk" + a15 +
	"kZGluZgAAABxkcmVm" + a10 + "EAAAAMdXJsIAAAAAEAAADTc3RibAAAAGdzdHNk" + a10 + "EAAABXbXA0YQAAAAAAAAAB" +
	a10 + "AAAgAQAAAAAKxEAAAAAAAzZXNkcwAAAAADgICAIgACAASAgIAUQBUAAAAAAfQAAAHz+QWAgIACEhAGgICAAQIAAAAYc3" +
	"R0cwAAAAAAAAABAAAAAgAABAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAIAAAABAAAAHHN0c3o" + a15 + "IAAAFzAAABdAAA" +
	"ABRzdGNv" + a10 + "EAAAAsAAAAYnVkdGEAAABabWV0YQAAAAAAAAAhaGRscg" + a10 + "bWRpcmFwcGw" + a15 +
	"AtaWxzdAAAACWpdG9vAAAAHWRhdGEAAAABAAAAAExhdmY1Ni40MC4xMDE=";
const vidStr = atob(b64VIDEO);
// The "new Blob()" constructor doesn't handle all code points well. For some
// reason my 1493 byte string was getting converted to a 1526 byte Blob. By
// splitting and mapping like this, we ensure a byte-for-byte translation
const vidArr = vidStr.split("").map(char => char.charCodeAt(0));
const VIDEO = new Blob([new Uint8Array(vidArr)], { type: "video/mp4" });

/* eslint-enable no-magic-numbers */

const ignoredEvents = ["timeupdate", "progress", "metadata", "texttrackchange"];
// Create "adX" and "contentX" versions of ignored events:
const length = ignoredEvents.length;
for (let i = 0; i < length; i++)
{
	ignoredEvents.push("ad" + ignoredEvents[i]);
	ignoredEvents.push("content" + ignoredEvents[i]);
}

/**
 * So this variable is really ugly. I needed some way to tell the middleware
 * (which is not part of our source handler) whether to trigger the playSource
 * method (which begins playing a new video source) or the normal play method
 * (which resumes a source that's already playing). This should toggle whenever
 * the content changes.
 */
let newVideoSource = true;

/**
 * Source Handlers are woefully undocumented on both the VideoJS website and in
 * their GitHub repo. I had to look at videojs-http-streaming for inspiration
 * and do a lot of experimentation to figure out how to get this working.
 *
 * Static JavaScript methods are actually compiled to only be available on the
 * class and not on instances (e.g. instance.canPlayType won't work, but
 * Class.canPlayType will). This leads to a weird quirk because we want these
 * to be methods on an instance (so we can keep a reference to the player and
 * options) but many of these methods have no bearing on the internal state of
 * the object (canPlayType is static), and VideoJS doesn't create its own
 * instance of SourceHandlers. It expects a SourceHandler to be a raw JSON
 * object with several static methods and it honestly doesn't expect you to keep
 * a reference to the player (for some reason).
 *
 * Not normally a huge concern, but eslint gets huffy if you have a non-static
 * method that doesn't depend on internal state.
 */
class VolarVMAPSourceHandler extends Component
{
	/**
	 * @constructor for compatibility with existing source handlers
	 */
	constructor()
	{
		super();
		this.name = "VolarVMAPSourceHandler";
		this.playerState = PLAYER_STATE.LOADING;

		// We'll have a collection of sources (network ID, content, etc)
		// Plugins can append to this sourceList by calling:
		//     player.tech_.sourceHandler_.addSource(srcObj, position)
		this.sourceList = [];
		this.currentSource = 0;
		this.nonContent = true;

		this.lastSourceList = [];

		this.boundReloadVMAP = this.reloadVMAP.bind(this);

		this.vmapRequest = null;

		this.disposed = false;

		this.boundDisableSeekingTU = this._disableSeekingTU.bind(this);
		this.boundDisableSeekingS = this._disableSeekingS.bind(this);

		this.unprocessedM3U8s = 0;
		this.playAttempted = false;
	}

	// eslint-disable-next-line class-methods-use-this
	canPlayType(type)
	{
		return VolarVMAPSourceHandler.canPlayType(type);
	}

	/* eslint-disable-next-line class-methods-use-this */
	canHandleSource(srcObj)
	{
		return VolarVMAPSourceHandler.canPlayType(srcObj.type) || VolarVMAPSourceHandler.canPlayUrl(srcObj.src);
	}

	static canPlayType(type)
	{
		const canPlay = /text\/xml|video\/x\.volar-vmap/.test(type);
		// logger.debug(`canPlayType(${type}) ? ${canPlay}`);
		return canPlay;
	}

	static canPlayUrl(url)
	{
		// If the VMAP URL is set programmatically rather than manually, it may
		// not have a valid "type". The VideoJS Mimetype utility simply has no
		// extension to work with
		// In this case, we'll just see if the URL looks roughly correct.
		const canPlay = /api\/broadcast\/vmap\/[0-9]+/.test(url);
		// logger.debug(`canPlayUrl(${url}) ? ${canPlay}`);
		return canPlay;
	}

	loadVmapError(vmapRequest)
	{
		let responseText = vmapRequest.responseText;
		if (typeof vmapRequest.responseText === "object")
		{
			try
			{
				responseText = JSON.stringify(responseText);
			}
			catch (error)
			{
				responseText = "Unknown";
			}
		}

		const errorMessage = `Failed to retrieve VMAP file from: ${this.source.src}. Response Text: ${responseText}`;
		this.loadError(errorMessage);
	}

	loadError(err)
	{
		let msg;
		if (err.message)
			msg = err.message;
		else
			msg = err;
		logger.error(`Failed to load media. Error: ${err}`);
		this.player.error(msg);
	}

	handleSource(srcObj, tech, options)
	{
		logger(`handleSource called: ${srcObj.src}`);

		this.source = srcObj;
		this.tech = tech;
		this.options = options;
		// I don't like accessing private members of the tech (options_), but
		// this is how videojs-http-streaming works (the OFFICIAL plugin for
		// HLS support), so I guess it's good enough
		this.player = videojs.players[this.tech.options_.playerId];

		this.vmap = null;

		// We will be delegating all playback to existing source handlers
		// Therefore let's get a list of valid ones (remove ourselves)
		this.sourceHandlers = this.tech.constructor.sourceHandlers.filter(handler => handler !== this);
		this.currentSourceHandler = null;
		this.configureDeferrableMethods();

		// We need to override the tech "trigger" method so we can intercept
		// events (namely the "ended" event, since we don't want it firing
		// between network ID and content)
		// TODO: Find a cleaner solution that doesn't involve polluting the
		// TODO: public player methods. Maybe using middleware?
		if (!this.player.triggerWrapped)
		{
			this.playerTrigger = this.player.trigger.bind(this.player);
			this.player.trigger = this.triggerWrapper.bind(this);
			this.player.triggerWrapped = true;
		}

		// Now we need to download the VMAP
		this.loadVMAP();
		this.player.trigger("loadstart");

		// Finally, let's create a hook that allows other plugins to reload the
		// VMAP. This will mostly be used during state polling (informing us of
		// a new content URL)
		this.player.on("reload-vmap", this.boundReloadVMAP);

		/*
		const playerPlay = this.player.play;
		this.player.play = () =>
		{
			this.player.play = playerPlay;
			this.playSource();
		};
		*/

		return this;
	}

	reloadVMAP()
	{
		// TODO: We may be reloading the VMAP for reasons other than new content
		// TODO: If the currently playing soure is still in the new VMAP, we
		// TODO: don't want to restart playback.

		this.clearTextTracks();

		// Now we need to download the VMAP
		newVideoSource = true;
		this.loadVMAP();
		this.player.trigger("loadstart");
	}

	loadVMAP()
	{
		// The Fetch API did not provide a way to set timeouts or cancel a
		// request early until much more recently (AbortController). However
		// this new method is not supported by enough browsers for us to use it.
		// Therefore for any request we need to be able to cancel, we must
		// instead use the old school XMLHttpRequest
		// Because we have to use this here, anyway, I may forego the Fetch
		// polyfill all together and write a utility for making requests. We'll
		// see.
		if (this.vmapRequest)
		{
			this.vmapRequest.abort();
		}
		const vmapRequest = new XMLHttpRequest();
		this.vmapRequest = vmapRequest;
		vmapRequest.onreadystatechange = () =>
		{
			if (vmapRequest.readyState === XHR_STATUS.READY)
			{
				if (vmapRequest.status === HTTP_STATUS.OK)
				{
					// Success
					if (this.vmapRequest === vmapRequest)
					{
						this.parseVMAP(vmapRequest.responseText);
						this.vmapRequest = null;
					}
					else
					{
						// We must have initiated a new request. Wait for it to
						// complete, and it can handle its own success.
					}
				}
				else if (vmapRequest.status === 0)
				{
					// The request was aborted for some reason
					logger("VMAP request aborted");
					if (this.vmapRequest === vmapRequest)
					{
						// The active VMAP request failed. That's an error.
						this.loadVmapError(vmapRequest);
					}
				}
				else
				{
					// Generic Error
					this.loadVmapError(vmapRequest);
				}
			}
		};
		this.vmapRequest.open("GET", this.source.src, true);
		this.vmapRequest.send();
	}

	parseVMAP(vmap)
	{
		logger("VMAP Loaded!");
		this.vmap = xPath(vmap, "/VMAP").iterateNext();

		// New VMAP means new sources
		this.sourceList = [];

		this.player.vmap = this.vmap;
		// TODO: Pass the VMAP with this event so we don't need to read player.vmap
		this.player.trigger("vmap-ready");

		if (!this.vmap)
		{
			return this.loadError("File was not a valid VMAP file");
		}

		const VVextension = xPath(this.vmap, "//Extensions/Extension[@type='VolarVMAPExtension']").iterateNext();

		if (!VVextension)
		{
			return this.loadError("VolarVMAPExtension not present in VMAP");
		}

		return this.vmap;
	}

	addSource(srcObj, position)
	{
		const srcPos = typeof position === "undefined" ? this.sourceList.length : position;
		this.sourceList.splice(srcPos, 0, srcObj);

		this.unprocessedM3U8s++;

		// Asynchronously process any M3U8s
		VolarVMAPSourceHandler.updateM3U8Source(srcObj)
			.then(() =>
			{
				this.unprocessedM3U8s--;

				this.player.trigger("sourceadded");

				if (this.playAttempted)
				{
					this.playAttempted = false;
					this.playSource();
				}
			})
			.catch(reason =>
			{
				this.player.trigger("contentblocked", reason);
			});
	}

	removeSource(srcObj)
	{
		const pos = this.sourceList.indexOf(srcObj);
		if (pos >= 0)
		{
			this.sourceList.splice(pos, 1);
		}
	}

	sourceListsMatch()
	{
		logger.debug(
			"Comparing sourcelists. Previous: " +
			this.lastSourceList.length + ", Current: " + this.sourceList.length
		);
		if (this.sourceList.length !== this.lastSourceList.length)
			return false;

		for (var i = 0; i < this.sourceList.length; i++)
		{
			logger.debug("    Previous[" + i + "] = " + this.lastSourceList[i].src);
			logger.debug("    Current[" + i + "]  = " + this.sourceList[i].src);
			if (this.sourceList[i].src !== this.lastSourceList[i].src)
				return false;
		}

		return true;
	}

	playSource()
	{
		if (this.unprocessedM3U8s > 0)
		{
			this.playAttempted = true;
			return;
		}

		logger("playSource called. currentSource: " + this.currentSource);
		if (!this.sourceListsMatch())
		{
			logger("sourceList has changed. Resetting currentSource.");
			// The source list has changed. Start over
			this.currentSource = 0;
			this.lastSourceList = this.sourceList;
			logger.debug("EVENT -- contentchanged");
			this.playerTrigger("contentchanged");
		}

		let nextSource;

		// Check for content override URL before checking the VMAP
		if (this.player.volarVMAP().options.contentOverride)
		{
			var srcUrl = this.player.volarVMAP().options.contentOverride;
			nextSource = {
				src: srcUrl,
				type: getMimetype(srcUrl)
			};
		}
		else if (this.player.vmapTagParserContent().hasContent)
		{
			nextSource = this.sourceList[this.currentSource];
			// If we previously locked the control bar, unlock it now
			this.player.controlBar.removeClass("vjs-lock-hidden");
		}
		else
		{
			// Only play content if our ContentTagParser found something
			// If not, we don't want to show a Network ID then say "haha, no
			// content for you". That's rude.
			// Because some plugins (notably ad plugins) may depend on content
			// existing, in this zero-content case we can populate the player
			// with a hard-coded 0-second video
			nextSource = {
				src: URL.createObjectURL(VIDEO),
				type: "video/mp4"
			};
			// If we don't have a real source, we shouldn't have a control bar
			this.player.controlBar.addClass("vjs-lock-hidden");
		}

		if (nextSource)
		{
			logger(`Playing source: ${nextSource.src}`);

			for (var i = 0; i < this.sourceHandlers.length; i++)
			{
				if (this.sourceHandlers[i].canHandleSource(nextSource))
				{
					this.playerState = PLAYER_STATE.PLAYING;

					// We update player.currentSrc() so that plugins can figure
					// out the actual video being played (e.g. Chromecast)
					if (this.player.ads)
					{
						this.player.ads.contentSrc = nextSource.src;
					}
					this.player.cache_.source = videojs.mergeOptions({}, nextSource);
					this.player.cache_.sources = [this.player.cache_.source];

					// Finally, play the new source
					if (this.currentSourceHandler)
					{
						this.currentSourceHandler.dispose();
					}
					this.currentSourceHandler = this.sourceHandlers[i].handleSource(
						nextSource, this.tech, this.options
					);

					this.nonContent = this.currentSource < this.sourceList.length - 1;
					this.configureDeferrableMethods();

					this.tech.play();
					if (!this.nonContent)
						this.player.trigger("playingcontent");

					return;
				}
			}

			this.loadError(`No valid source handler exists for type: ${nextSource.type}`);
		}
	}

	static updateM3U8Source(source)
	{
		return new Promise((resolve, reject) =>
		{
			if (source.type === "application/x-mpegURL" && source.src.indexOf(".m3u8") >= 0)
			{
				// Rip out the audio-only rendition(s)
				logger.debug("VolarVMAPSourceHandler: Using custom M3U8 handling");

				if (source.src.substr(0, 2) === "//")
					source.src = "https:" + source.src;

				fetch(source.src)
					.then(resp =>
					{
						if (resp.status === HTTP_STATUS.OK)
						{
							resolve(source);
						}
						else if (resp.status === HTTP_STATUS.FORBIDDEN)
						{
							// We were blocked for some reason
							resp.text()
								.then(reason =>
								{
									if (reason.indexOf("<Error>") >= 0)
									{
										// Treat it like a 404
										setTimeout(() =>
										{
											this.updateM3U8Source(source).then(resolve).catch(reject);
										}, _m3u8PollInterval);
									}
									else
									{
										source.src = "BLOCKED";
										reject(reason);
									}
								});
						}
						else
						{
							// A non-403 error occurred
							setTimeout(() =>
							{
								this.updateM3U8Source(source).then(resolve).catch(reject);
							}, _m3u8PollInterval);
						}
					});
			}
			else
			{
				resolve(source);
			}
		});
	}

	triggerWrapper(type, hash)
	{
		const flatType = typeof type === "object" ? type.type : type;

		if (flatType === "contentchanged")
		{
			// Only WE should trigger contentchanged events...
			// TODO: Test that we are handling the current player source
			return;
		}

		if (ignoredEvents.indexOf(flatType) === -1)
		{
			logger.debug("EVENT -- " + flatType);
		}

		if (flatType === "ended" && (!this.player.ads || !this.player.ads.ad))
		{
			if (this.currentSource >= this.sourceList.length - 1)
			{
				this.playerTrigger("ended", hash);
			}
			else
			{
				logger.debug("\"ended\" event suppressed, playing next source");
				this.currentSource++;
				this.playSource();
				this.player.play();
			}
		}
		else
		{
			this.playerTrigger(type, hash);
		}
	}

	dispose()
	{
		this.player.off("reload-vmap", this.boundReloadVMAP);
	}

	// These three methods are listed as "deferrable" in VideoJS Tech
	// https://github.com/videojs/video.js/blob/e612056bfa8e6e77ca6379e74ba14e9a75da32d6/src/js/tech/tech.js#L1199
	// For now, we'll just pass through to any current sourceHandler
	// Eventually we may want to do things like "don't allow seekin during network ID"
	// For method definitions:
	// https://www.w3schools.com/tags/ref_av_dom.asp

	configureDeferrableMethods()
	{
		logger(`Configuring deferrable methods. Content: ${!this.nonContent}`);
		delete this.duration;
		delete this.seekable;
		delete this.seeking;

		// Just for brevity of source code
		const handler = this.currentSourceHandler;

		if ((handler && handler.duration) || this.nonContent) this.duration = this._duration;
		if ((handler && handler.seekable) || this.nonContent) this.seekable = this._seekable;
		if ((handler && handler.seeking) || this.nonContent) this.seeking = this._seeking;

		if (this.nonContent)
			this.disableSeeking();
		else
			this.enableSeeking();
	}

	disableSeeking()
	{
		// Hide the progress bar
		this.player.controlBar.progressControl.hide();
		// In case they try to seek via the API
		this.player.on("timeupdate", this.boundDisableSeekingTU);
		this.player.on("seeking", this.boundDisableSeekingS);
		// Finally, fire an event for any plugins that want to react
		this.player.trigger("seekingdisabled");

		// A hack so that we can seek the network ID after ads
		// Basically unless someone knows about this super-secret event, they
		// won't be able to programmatically trigger a seek
		this.player.on("bypassSeekPrevention", () =>
		{
			this.enableSeeking();
			setTimeout(() =>
			{
				this.disableSeeking();
			}, 100);
		});
	}

	// TU = TimeUpdate handler
	// This method is called when the "time update" event is fired
	_disableSeekingTU()
	{
		this.lastKnownTime = this.player.currentTime();
	}

	// S = Seeking handler
	// This method is called when the "seeking" event is fired
	_disableSeekingS()
	{
		// Guard against infinite recursion:
		// user seeks, seeking is fired, currentTime is modified, seeking is fired, ...
		const delta = Math.abs(this.player.currentTime() - this.lastKnownTime);
		if (delta > _seekingThreshold)
		{
			logger("Reverting attempted seek");
			this.player.currentTime(this.lastKnownTime);
		}
	}

	enableSeeking()
	{
		// Undo what we did before
		this.player.controlBar.progressControl.show();
		this.player.off("timeupdate", this.boundDisableSeekingTU);
		this.player.off("seeking", this.boundDisableSeekingS);
		this.player.trigger("seekingenabled");

		this.player.off("bypassSeekPrevention");
	}

	_duration()
	{
		if (this.playerState === PLAYER_STATE.LOADING || this.nonContent)
			return 0;
		else
			return this.currentSourceHandler.duration();
	}

	_seekable()
	{
		if (this.playerState === PLAYER_STATE.LOADING || this.nonContent)
			return videojs.createTimeRanges();
		else
			return this.currentSourceHandler.seekable();
	}

	_seeking()
	{
		if (this.playerState === PLAYER_STATE.LOADING || this.nonContent)
			return false;
		else
			return this.currentSourceHandler.seeking();
	}

	clearTextTracks()
	{
		// Im concerned of potential race condition problems if this was done with the reload-vmap trigger.
		// The new text tracks may get cleared if they are added and removed asynchronously.

		// Browers with native text track support (Safari) may handle new text tracks differently
		// so, we will not clear their text tracks on a reload.
		const nativeTextTrack = this.player.tech_.featuresNativeTextTracks;

		// When the vmap is reloaded the new text tracks are just added to the current list causing duplicates
		// in the subtitle selector.
		const textTracks = this.player.textTracks();
		if (!nativeTextTrack)
		{
			// Iterate backwards because we are removing elements from the list, thus changing the index of the tracks.
			for (let i = textTracks.length - 1; i >= 0; i--)
			{
				textTracks.removeTrack(textTracks[i]);
			}
		}
	}
}

// Register the SourceHandler with the HTML5 tech
// "0" means that this SourceHandler will be called before any others
videojs.getTech("Html5").registerSourceHandler(new VolarVMAPSourceHandler(), 0);

// Awkward...
videojs.use("*", function (player)
{
	return {
		// HTML5 tech only works if the <video> tag has a "src" attribute
		// Until we've played one video, that's not the case. Because the
		// "src" attribute gets set in playSource()
		callPlay: function ()
		{
			if (newVideoSource)
			{
				newVideoSource = false;
				return player.tech_.sourceHandler_.playSource();
			}
			else
			{
				return player.tech_.play();
			}
		}
	};
});
