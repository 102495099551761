/**
 * This file serves to maintain compatibility with the old player initialization
 * logic from the CMS. The vast majority of this was copied and pasted directly
 * from @dreager's `__init__.js` and `utilities/Setup.js` scripts
 */
import { version } from "../package.json";
import videojs from "video.js";
import { parseUrl } from "../utilities/Url";
import Cache from "../utilities/Cache";
import { getEnvironment } from "@utilities/Env";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/browser";

const logger = videojs.createLogger("Setup");

// TODO: This isn't security
// just to make it harder for bots scraping urls
const adminHostnames = [
	// btoa("blueframe-player-flash.s3.amazonaws.com")
	"Ymx1ZWZyYW1lLXBsYXllci1mbGFzaC5zMy5hbWF6b25hd3MuY29t",
	// btoa("localhost")
	"bG9jYWxob3N0",
	// btoa("test.local")
	"dGVzdC5sb2NhbA==",
	// btoa('10.115.2.27') office ip
	"MTAuMTE1LjIuMjc="
];
// blocks/allows admin config options
let privileged = adminHostnames.indexOf(btoa(window.location.hostname)) >= 0;

// Will be set later
var config;

const adminConfig = {
	no_ads: true,
	no_network_id: true,
	no_analytics: true
};

const DEFAULT_CONFIG = {
	npaw_enabled: true
};

// eslint-disable-next-line no-magic-numbers
const PLAYBACK_RATES = [0.25, 0.5, 1.0, 1.5, 2.0, 4.0];
const AD_PLUGINS = ["ima", "ads", "imaDai"];

var setup = (function ()
{
	// Not very efficient, but fixes bools and numbers being strings
	function parseJsonObject(object)
	{
		return JSON.parse(JSON.stringify(object), function (key, value)
		{
			if (typeof value === "string")
			{
				try
				{
					return JSON.parse(value);
				}
				catch (e)
				{
					return value;
				}
			}
			else
			{
				return value;
			}
		});
	}

	// Combines url parameters, player_config and defaults_config into one object
	function setupConfig()
	{
		var params = parseUrl(window.location).parameters;

		const playerConfig = window.player_config || {};

		// Some options like allow_test are found in player_config.settings and
		// not directly in player_config. This is a really stupid and simple way
		// to make less work for myself
		config = parseJsonObject(videojs.mergeOptions({}, DEFAULT_CONFIG, playerConfig, playerConfig.settings, params));
		config.features = config.features || {};
		config.limit = config.limit || {};

		if (DOMPurify.isSupported)
		{
			config.vmap_url = DOMPurify.sanitize(config.vmap_url);
		}

		// An encoded url will break things
		config.vmap_url = decodeURIComponent(config.vmap_url);
		config.logging = config.logging ? typeof config.logging === "string" ? config.logging : "info" : "silent";
		let playerDomain = config.playerDomain;
		try
		{
			if (window.self === window.top || playerDomain === "")
				playerDomain = window.location.href;
		}
		catch (e) { /* do nothing */ }
		config.domain = playerDomain;

		const vmapDetails = parseUrl(config.vmap_url);
		config.broadcast_id = vmapDetails.pathname.split("/").pop();

		// TODO: This is a real bad way to detect admin player
		const admin = config.domain.indexOf("/admin/") >= 0;
		privileged = privileged || admin;

		if (!privileged)
		{
			// Disable these features for non-privileged users
			for (var key in adminConfig)
				if (Object.prototype.hasOwnProperty.call(adminConfig, key))
					config[key] = false;
		}

		if (typeof google === "undefined")
		{
			logger.debug("IMA3 did not load. Disabling ads.");
			config.no_ads = true;
		}

		if (typeof NpawPlugin === "undefined" || typeof NpawPluginAdapters === "undefined")
		{
			logger.debug("NpawPlugin did not load. Disabling Npaw.");
			config.npaw_enabled = false;
		}

		// Admin players should have full logging
		config.logging = admin ? "trace" : config.logging;

		// Default
		config.autohide_controls = config.autohide_controls || true;

		// Thumbnails are not support with content override, for obvious reasons
		config.features.thumbnails = !config.content_override_url;

		// Until we add proper support to the CMS (to put this in the
		// player_config), we can read the window parameters manually
		if (typeof config.no_automute === "undefined")
		{
			config.no_automute = window.location.href.indexOf("automute=0") >= 0;
		}

		if (config.caching === false)
		{
			Cache.disable();
		}
	}

	// Used to do a lot more. Now just logs the player version and config
	function setupLogging()
	{
		logger(`BluePlayer v${version}`);
		if (privileged) logger("\tAdmin mode active");

		logger("\tBroadcast: " + config.broadcast_id);
		logger("Config: ", JSON.stringify(config));
	}

	function setupSentry()
	{
		Sentry.init({
			dsn: "https://34cbb6cc8ea25e22c547763d4baab49d@o108923.ingest.sentry.io/4506712993497088",
			integrations: [
				Sentry.replayIntegration(),
				Sentry.captureConsoleIntegration({
					levels: ["error"]
				})
			],
			release: version,
			environment: getEnvironment(config.vmap_url) === "prod" ? "production" : "staging",

			tracesSampleRate: 0.0, //  Do not capture transactions
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", "https://staging.vcloud.thorhudl.com", "https://vcloud.hudl.com"],
			// Session Replay
			replaysSessionSampleRate: 0.0, // Do not record successful user sessions at all
			replaysOnErrorSampleRate: 1.0, // Every session in which an error occurs will be recorded
			beforeSend(event)
			{
				event.tags = event.tags || {};
				event.tags.broadcast_id = config.broadcast_id || "Unknown";
				event.tags.user_id = config.user_id || "Unknown";
				return event;
			},
		});
	}

	return function ()
	{
		setupConfig();
		setupLogging();
		setupSentry();
	};
})();

function isAdPlugin(pluginName)
{
	return AD_PLUGINS.includes(pluginName);
}

function initHTML5Player()
{
	// TODO: We may need to load our own CSS in case the appropriate CSS file
	// TODO: was not included on the page. Honestly, though, if they're
	// TODO: including volarplayer.min.js, I don't think it's a stretch to
	// TODO: expect them to include volarplayer.min.css

	// Prevent scroll bars on the body
	document.body.style.overflow = "hidden";

	// Removes elements added by embed.php and player-setup.js
	document.body.innerHTML =
		`<video
			id="video_container"
			class="video-js"
			playsinline
			${config.auto_play ? "autoplay" : ""}
			${config.poster ? "poster=\"" + config.poster + "\"" : ""}
			${config.volume ? "volume=\"" + config.volume + "\"" : ""}
			style="width:100%; height:100%; position:relative;"
		></video>`;

	// Initializes our player
	const app = window.bfplayer("video_container", {
		// The default "inactivityTimeout" is 2000ms
		// Setting it to 0 disables inactivity (control bar won't hide)
		inactivityTimeout: config.autohide_controls ? 2 * 1000 : 0,
		/* eslint-disable-next-line no-magic-numbers */
		playbackRates: PLAYBACK_RATES,
	});

	// This allows the players start time to be set with a URL param
	// 't' isnt super descriptive but thats what youtube uses. so I assume 't' = time.
	if (config.t && parseInt(config.t) > 0)
	{
		app.currentTime(config.t);
	}

	// If videojs-contrib-ads is available, it MUST be loaded first
	// This is because videojs-contrib-ads overrides a lot of player events
	if (Object.prototype.hasOwnProperty.call(videojs.getPlugins(), "ads"))
	{
		app.ads();
	}

	app.ENVIRONMENT = getEnvironment(config.vmap_url);

	// Finally, pass any configuration options to plugins that need to be aware
	// of them. We place this here instead of in the VideoJS options so that if
	// a plugin is disabled or removed it won't break player initialization
	const pluginOptions = {
		blueframeAds: {
			enabled: !config.no_ads,
			descriptionUrl: config.domain
		},
		imaAds: {
			enabled: !config.no_ads,
			logMetadata: config.logMetadata,
			descriptionUrl: config.domain,
			disableApsHeaderBidding: config.disableApsHeaderBidding
		},
		liveDVR: {
			enabled: typeof config.dvr === "undefined" ? true : config.dvr,
			temporalDelay: config.default_temporal_delay
		},
		volarVMAP: {
			contentOverride: config.content_override_url
		},
		behaviorChangeAutoplayFix: {
			enabled: !config.no_automute
		},
		// eslint-disable-next-line id-length
		behaviorChangeLimitConcurrentViewers: {
			serviceUrl: config.limit.service_url,
			heartbeatInterval: config.limit.heartbeat_interval,
			timeout: config.limit.timeout,
			orderNumber: config.limit.order_number,
			concurrentViewers: config.limit.concurrent_viewers,
			signature: config.limit.signature
		},
		controlBarButtonBlueFrameClipping: {
			enabled: privileged
		},
		controlBarButtonPopupWindow: {
			force: config.popup_window_button
		},
		infoMenuOptionReleaseNotes: {
			enable: privileged
		},
		infoMenuOptionStatsForNerds: {
			open: config.show_stats
		},
		vmapTagParserNetworkID: {
			enabled: !config.no_network_id
		},
		vmapTagParserNotifiers: {
			allow_test: config.allow_test,
			start_override: config.start_time,
			overrideStatus: config.status,
			messages: {
				upcoming: config.not_streaming_message
			}
		},
		vmapTagParserPreviewSets: {
			enabled: config.features.thumbnails
		},
		vmapTagParserStats: {
			enabled: !config.no_analytics,
			domain: config.domain,
			purchaseOrderNumber: config.limit.order_number
		},
		Hotkeys: {
			playbackRates: PLAYBACK_RATES
		},
		youbora: {
			enabled: config.npaw_enabled,
			platform: "FanExperience",
			entityType: "Video",
			broadcastID: config.broadcast_id,
			username: config.user_id,
			appName: "bf-webplayer"
		},
		hudlMetadataHandler: {
			enabled: !config.no_ads || config.npaw_enabled,
			broadcastID: config.broadcast_id,
		},
	};

	Object.keys(videojs.getPlugins()).forEach(plugin =>
	{
		if (typeof app[plugin] === "function")
		{
			if (!isAdPlugin(plugin))
			{
				try
				{
					app[plugin](pluginOptions[plugin] || {});
				}
				catch (e)
				{
					console.error(`Plugin fail to initialize: ${plugin}.`, e);
				}
			}
		}
	});

	// Sets the player source to our VMAP URL
	app.src({
		src: config.vmap_url,
		type: "video/x.volar-vmap"
	});

	// Make logs more easily accessible programmatically
	app.getLogs = videojs.log.history;

	return app;
}

function initPlayer()
{
	setup();

	return initHTML5Player();
}

function loadScript(src)
{
	return new Promise((resolve) =>
	{
		var script = document.createElement("script");
		script.type = "text/javascript";
		script.src = src;

		script.onload = () =>
		{
			logger.debug(src + " loaded successfully.");
			resolve();
		};

		script.onerror = () =>
		{
			// The player can still run if ima and aps both fail.
			logger.debug("Error loading " + src);
			resolve();
		};

		document.head.appendChild(script);
	});
}

window.$v = function ()
{
	Promise.all([
		loadScript("//imasdk.googleapis.com/js/sdkloader/ima3.js"),
		loadScript("//c.amazon-adsystem.com/aax2/apstag.js"),
		loadScript("https://artifact.plugin.npaw.com/artifactory/plugins/js/7.3.4/NpawPlugin-nwf.js"),
		loadScript("https://artifact.plugin.npaw.com/artifactory/plugins/js/7.3.4/NpawPluginAdapters.js")
	]).then(() =>
	{
		const player = initPlayer();

		// For backwards compatibility with player-setup.js
		// TODO: Probably make a plugin so addEventListener can be an alias for on,
		// TODO: because that's pretty standard stuff
		// Such a plugin would have to come after v5.0.0 (and the deprecation of
		// player-setup.js) because player-setup.js passes a string, not a function.
		// Alternatively we could build it to accept strings (converting them to
		// functions) and deprecate that usage - but that's ugly.
		player.addEventListener = function () { /* do nothing for now */ };

		// This kills the player-setup
		window.$v = function () { return player; };

		window.player = player;

		// $v wont actually return anything because
		// the player is initialized after a timeout/async trigger
		return player;
	});
};
